import React from "react"
import { navigate } from "gatsby"
import {
  getCourseNavigation,
  useCourseStore,
} from "../../../../../store/courses"
import LayoutCourse from "../../../../../layouts/course"

import Box from "../../../../../components/box"
import Paragraph from "../../../../../components/paragraph"
import CourseFooterNext from "../../../../../components/course-footer-next"
import Heading from "../../../../../components/heading"
import Hyphen from "../../../../../components/hyphen"
import Inline from "../../../../../components/inline"
import Note from "../../../../../components/note"
import Poem from "../../../../../components/poem"
import Seo from "../../../../../components/seo"
import Stack from "../../../../../components/stack"
import TaskSidebar from "../../../../../components/task-sidebar"
import TextPicker from "../../../../../components/text-picker"

const pickerSet = [
  { title: "betonte Silbe", content: "—" },
  { title: "unbetonte Silbe", content: "◡" },
]

const Page = () => {
  const { submitForm } = useCourseStore()
  const navigation = getCourseNavigation({ courseId: "haelfte-des-lebens" })

  return (
    <LayoutCourse
      as="form"
      onSubmit={(event) => {
        event.preventDefault()
        submitForm({
          event,
          courseId: "haelfte-des-lebens",
          chapterId: "01-zwei-haelften",
          taskId: "versmass-bestimmen",
        })
        navigate(
          "/kurse/haelfte-des-lebens/01-zwei-haelften/versmass-bestimmen/loesung"
        )
      }}
      navigation={navigation}
      footer={<CourseFooterNext type="submit" value="submit" />}
    >
      <Seo title="Versmaß bestimmen" />
      <Box>
        <Stack>
          <Stack space={3}>
            <Heading as="h1" level={2}>
              Versmaß bestimmen
            </Heading>
          </Stack>
          <Paragraph>
            Wir haben dir das Gedicht bereits in seine Silben zerlegt. Jetzt
            liegt es an dir, zu bestimmen, welche davon betont und welche
            unbetont ist. Betonte Silben erkennt man daran, dass sie lauter und
            hervorgehobener gesprochen werden als die unbetonten.
          </Paragraph>

          <TaskSidebar
            main={
              <Stack space={10}>
                <Stack space={3}>
                  <Poem>
                    <p>
                      Häl
                      <Hyphen />
                      fte des Le
                      <Hyphen />
                      bens
                    </p>
                  </Poem>
                  <Inline>
                    <TextPicker id="q-0" set={pickerSet} />
                    <TextPicker id="q-1" set={pickerSet} />
                    <TextPicker id="q-2" set={pickerSet} />
                    <TextPicker id="q-3" set={pickerSet} />
                    <TextPicker id="q-4" set={pickerSet} />
                  </Inline>
                </Stack>
                <Stack space={3}>
                  <Poem>
                    <p>
                      Mit gel
                      <Hyphen />
                      ben Bir
                      <Hyphen />
                      nen hän
                      <Hyphen />
                      get
                    </p>
                  </Poem>
                  <Inline>
                    <TextPicker id="q-5" set={pickerSet} />
                    <TextPicker id="q-6" set={pickerSet} />
                    <TextPicker id="q-7" set={pickerSet} />
                    <TextPicker id="q-8" set={pickerSet} />
                    <TextPicker id="q-9" set={pickerSet} />
                    <TextPicker id="q-10" set={pickerSet} />
                    <TextPicker id="q-11" set={pickerSet} />
                  </Inline>
                </Stack>
                <Stack space={3}>
                  <Poem>
                    <p>
                      Und voll mit wil
                      <Hyphen />
                      den Ro
                      <Hyphen />
                      sen
                    </p>
                  </Poem>
                  <Inline>
                    <TextPicker id="q-12" set={pickerSet} />
                    <TextPicker id="q-13" set={pickerSet} />
                    <TextPicker id="q-14" set={pickerSet} />
                    <TextPicker id="q-15" set={pickerSet} />
                    <TextPicker id="q-16" set={pickerSet} />
                    <TextPicker id="q-17" set={pickerSet} />
                    <TextPicker id="q-18" set={pickerSet} />
                  </Inline>
                </Stack>
                <Stack space={6}>
                  <Poem as="p">Das Land in den See,</Poem>
                  <Inline>
                    <TextPicker id="q-19" set={pickerSet} />
                    <TextPicker id="q-20" set={pickerSet} />
                    <TextPicker id="q-21" set={pickerSet} />
                    <TextPicker id="q-22" set={pickerSet} />
                    <TextPicker id="q-23" set={pickerSet} />
                  </Inline>
                </Stack>
                <Stack space={3}>
                  <Poem>
                    <p>
                      Ihr hol
                      <Hyphen />
                      den Schwä
                      <Hyphen />
                      ne,
                    </p>
                  </Poem>
                  <Inline>
                    <TextPicker id="q-24" set={pickerSet} />
                    <TextPicker id="q-25" set={pickerSet} />
                    <TextPicker id="q-26" set={pickerSet} />
                    <TextPicker id="q-27" set={pickerSet} />
                    <TextPicker id="q-28" set={pickerSet} />
                  </Inline>
                </Stack>
                <Stack space={3}>
                  <Poem>
                    <p>
                      Und trun
                      <Hyphen />
                      ken von Küs
                      <Hyphen />
                      sen
                    </p>
                  </Poem>
                  <Inline>
                    <TextPicker id="q-29" set={pickerSet} />
                    <TextPicker id="q-30" set={pickerSet} />
                    <TextPicker id="q-31" set={pickerSet} />
                    <TextPicker id="q-32" set={pickerSet} />
                    <TextPicker id="q-33" set={pickerSet} />
                    <TextPicker id="q-34" set={pickerSet} />
                  </Inline>
                </Stack>
                <Stack space={6}>
                  <Poem as="p">Tunkt ihr das Haupt</Poem>
                  <Inline>
                    <TextPicker id="q-35" set={pickerSet} />
                    <TextPicker id="q-36" set={pickerSet} />
                    <TextPicker id="q-37" set={pickerSet} />
                    <TextPicker id="q-38" set={pickerSet} />
                  </Inline>
                </Stack>
                <Stack space={3}>
                  <Poem>
                    <p>
                      Ins hei
                      <Hyphen />
                      lig
                      <Hyphen />
                      nüch
                      <Hyphen />
                      ter
                      <Hyphen />
                      ne Was
                      <Hyphen />
                      ser.
                    </p>
                  </Poem>
                  <Inline>
                    <TextPicker id="q-39" set={pickerSet} />
                    <TextPicker id="q-40" set={pickerSet} />
                    <TextPicker id="q-41" set={pickerSet} />
                    <TextPicker id="q-42" set={pickerSet} />
                    <TextPicker id="q-43" set={pickerSet} />
                    <TextPicker id="q-44" set={pickerSet} />
                    <TextPicker id="q-45" set={pickerSet} />
                    <TextPicker id="q-46" set={pickerSet} />
                  </Inline>
                </Stack>
                <Stack space={6}>
                  <Poem as="p">Weh mir, wo nehm’ ich, wenn</Poem>
                  <Inline>
                    <TextPicker id="q-47" set={pickerSet} />
                    <TextPicker id="q-48" set={pickerSet} />
                    <TextPicker id="q-49" set={pickerSet} />
                    <TextPicker id="q-50" set={pickerSet} />
                    <TextPicker id="q-51" set={pickerSet} />
                    <TextPicker id="q-52" set={pickerSet} />
                  </Inline>
                </Stack>
                <Stack space={3}>
                  <Poem>
                    <p>
                      Es Win
                      <Hyphen />
                      ter ist, die Blu
                      <Hyphen />
                      men, und wo
                    </p>
                  </Poem>
                  <Inline>
                    <TextPicker id="q-53" set={pickerSet} />
                    <TextPicker id="q-54" set={pickerSet} />
                    <TextPicker id="q-55" set={pickerSet} />
                    <TextPicker id="q-56" set={pickerSet} />
                    <TextPicker id="q-57" set={pickerSet} />
                    <TextPicker id="q-58" set={pickerSet} />
                    <TextPicker id="q-59" set={pickerSet} />
                    <TextPicker id="q-60" set={pickerSet} />
                    <TextPicker id="q-61" set={pickerSet} />
                  </Inline>
                </Stack>
                <Stack space={3}>
                  <Poem>
                    <p>
                      Den Son
                      <Hyphen />
                      nen
                      <Hyphen />
                      schein,
                    </p>
                  </Poem>
                  <Inline>
                    <TextPicker id="q-62" set={pickerSet} />
                    <TextPicker id="q-63" set={pickerSet} />
                    <TextPicker id="q-64" set={pickerSet} />
                    <TextPicker id="q-65" set={pickerSet} />
                  </Inline>
                </Stack>
                <Stack space={3}>
                  <Poem>
                    <p>
                      Und Schat
                      <Hyphen />
                      ten der Er
                      <Hyphen />
                      de?
                    </p>
                  </Poem>
                  <Inline>
                    <TextPicker id="q-66" set={pickerSet} />
                    <TextPicker id="q-67" set={pickerSet} />
                    <TextPicker id="q-68" set={pickerSet} />
                    <TextPicker id="q-69" set={pickerSet} />
                    <TextPicker id="q-70" set={pickerSet} />
                    <TextPicker id="q-71" set={pickerSet} />
                  </Inline>
                </Stack>
                <Stack space={3}>
                  <Poem>
                    <p>
                      Die Mau
                      <Hyphen />
                      ern stehn
                    </p>
                  </Poem>
                  <Inline>
                    <TextPicker id="q-72" set={pickerSet} />
                    <TextPicker id="q-73" set={pickerSet} />
                    <TextPicker id="q-74" set={pickerSet} />
                    <TextPicker id="q-75" set={pickerSet} />
                  </Inline>
                </Stack>
                <Stack space={3}>
                  <Poem>
                    <p>
                      Sprach
                      <Hyphen />
                      los und kalt, im Win
                      <Hyphen />
                      de
                    </p>
                  </Poem>
                  <Inline>
                    <TextPicker id="q-76" set={pickerSet} />
                    <TextPicker id="q-77" set={pickerSet} />
                    <TextPicker id="q-78" set={pickerSet} />
                    <TextPicker id="q-79" set={pickerSet} />
                    <TextPicker id="q-80" set={pickerSet} />
                    <TextPicker id="q-81" set={pickerSet} />
                    <TextPicker id="q-82" set={pickerSet} />
                  </Inline>
                </Stack>
                <Stack space={3}>
                  <Poem>
                    <p>
                      Klir
                      <Hyphen />
                      ren die Fah
                      <Hyphen />
                      nen.
                    </p>
                  </Poem>
                  <Inline>
                    <TextPicker id="q-83" set={pickerSet} />
                    <TextPicker id="q-84" set={pickerSet} />
                    <TextPicker id="q-85" set={pickerSet} />
                    <TextPicker id="q-86" set={pickerSet} />
                    <TextPicker id="q-87" set={pickerSet} />
                  </Inline>
                </Stack>
              </Stack>
            }
            sidebar={
              <Note variant="task">
                Bestimme für jede Silbe des Gedichts, ob sie betont oder
                unbetont ist.
                <br />
                Klicke auf die Kästchen und wähle den Strich — für betonte
                Silben und den halboffenen Bogen ◡ für unbetonte Silben
              </Note>
            }
          />
        </Stack>
      </Box>
    </LayoutCourse>
  )
}

export default Page
